<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <img :src="`${publicPath}HB_LOGO.png`">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
            （南関東）
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col
          class="mb-0"
          cols="12"
          md="8"
        >
          <v-text-field
            prepend-icon="mdi-account-circle"
            label="担当者ID"
            v-model="appinfo.userId"
          />
          <v-text-field
            v-bind:type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            prepend-icon="mdi-lock"
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            v-model="password"
          />
          <v-checkbox
            v-model="remember"
          >
            <template slot="label">
              <div>
                次回から担当者コードの入力を省略
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row dense
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
        <div>
          <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="loginCheck()">ログイン</v-btn>
        </div>
        </v-col>
      </v-row>
      <v-row dense
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <p class="blue--text my-5">
            パスワードを忘れた場合は管理者に連絡してください
          </p>
          <v-alert
            v-model="login_error"
            type="error"
            dismissible
            dense
            outlined
          >
            {{ error_message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'LogIn',
  data: () => ({
    showPassword: false,
    appinfo: { userId: '' },
    password: '',
    remember: true,
    login_error: false,
    error_message: '',
    passwordReset: '0',
    publicPath: process.env.BASE_URL
  }),
  mounted () {
    // 起動時には、localStorageに保存されたユーザを呼び出す
    if (localStorage.getItem('appinfo')) {
      try {
        this.appinfo = JSON.parse(localStorage.getItem('appinfo'))
        console.log('localstorage=', this.appinfo)
      } catch {
        localStorage.removeItem('appinfo')
        localStorage.clear()
      }
    }
  },
  created () {
    console.log(process.env)

    // vuex情報などの初期化
    this.$store.dispatch('clearState')
  },
  methods: {
    // ログインボタン押下時の入力チェック
    loginCheck () {
      this.login_error = false
      if (this.appinfo.userId === '') {
        console.log('errstatus:', 400)
        console.log('message:', '担当者IDを入力してください。')
        this.login_error = true
        this.error_message = '担当者IDを入力してください。'
      } else {
        if (this.password === '') {
          console.log('errstatus:', 400)
          console.log('message:', 'パスワードを入力してください。')
          this.login_error = true
          this.error_message = 'パスワードを入力してください。'
          return
        }
        // チェックＯＫの場合ユーザ認証処理を行う
        this.login()
      }
    },
    // ユーザ認証
    login () {
      this.login_error = false
      this.axios.get('https://bhiy0gfawh.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/login', {
        params: {
          loginGr: 'BB',
          userId: this.appinfo.userId,
          password: this.password
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 取得データでvuex情報を更新
          this.$store.dispatch('setUserId', this.appinfo.userId) // 担当者ID
          this.$store.dispatch('setNowPassword', response.data.password) // 現在のパスワード
          this.$store.dispatch('setDeptCd', response.data.deptCd) // 部署CD
          this.$store.dispatch('setDeptNm', response.data.deptNm) // 部署名
          this.$store.dispatch('setCenterCd', response.data.centerCd) // センタCD
          this.$store.dispatch('setDeptId', response.data.deptId) // 部署ID
          // this.$store.dispatch('setUserCd', response.data.userCd) // ユーザCD
          this.$store.dispatch('setMessage', response.data.message) // メッセージ
          this.$store.dispatch('setLoginGr', response.data.loginGr) // ログインGr
          // パスワードリセット設定
          this.passwordReset = response.data.passwordReset
          // ユーザ認証に成功した場合、シフト計画データ存在チェック
          this.shiftExistChk()
        }).catch(err => {
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.login_error = true
          this.error_message = err.response.data
        })

      // 入力値はlocalStorageへ保存、またアプリ生存中はvuexでも保存する。
      if (this.remember) {
        console.log('save params to local storage=', this.appinfo)
        localStorage.setItem('appinfo', JSON.stringify(this.appinfo))
      } else {
        localStorage.removeItem('appinfo')
        localStorage.clear()
      }
    },
    // シフト計画データ存在チェック
    shiftExistChk () {
      this.login_error = false
      this.axios.get('https://xzkfry4ckc.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/shiftexistchk', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptId: this.$store.getters.getDeptId,
          userId: this.$store.getters.getUserId
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 取得データでvuex情報を更新
          this.$store.dispatch('setRequestMonth', response.data.requestMonth) // ヘッダ表示用対象年月
          this.$store.dispatch('setSearchRequestMonth', response.data.requestMonth) // 検索用対象年月
          // パスワードリセットが1:強制の場合はパスワード画面へ遷移
          if (this.passwordReset === '1') {
            this.$store.dispatch('setOriginalScreen', 'LogIn') // 遷移元画面
            // ユーザ認証でエラーが無かった場合、メッセージを設定
            if (this.$store.getters.getMessage === undefined) {
              this.$store.dispatch('setMessage', 'パスワードを変更してください')
            }
            this.$router.push({ name: 'LoginPassEdit' })
          } else {
            // メニュー画面へ遷移
            this.$router.push({ name: 'ShiftplanMenu' })
          }
        }).catch(err => {
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.login_error = true
          this.error_message = err.response.data
        })
    }
  }
}
</script>

<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }

</style>
